<template>
    <div style="overflow-y: scroll;" >
        <PageTitle title="系统信息" fixed backTo="/message"></PageTitle>
        <div  style="overflow-y: scroll;" id="dhk" fixed :style="height">
            <van-cell v-for="(msgtext,index) in receiveMsgText" :key="index">
                <div style="clear: both;">
                    <p style="color: #dfdfdf;left: 0;right: 0;text-align: center" >
                        {{PD(index)}}
                    </p>
                </div>
                <a>
                    <img :src="imgSrc" style="float: left;margin-top: 10px;width:44px;height: 44px">
                    <div style="float: left">
                        <label style="color:#000;font-size: 12px; margin: 0 5px;">{{ hospitalName }}</label>
                        <br>
                      <div>
                        <p class="doctorCommunicationInputs" style="float: left;"  >{{msgtext.msg.title}}<br>{{msgtext.msg.content}}</p>
                    </div>
                    </div>
                </a>
            </van-cell>
        </div>

        <!--<div style="background: #ffffff;position: fixed;bottom: 0" >-->
            <!--<van-field class="communicationInput" v-model="sendMsg.msg"-->
                       <!--maxlength="1000"-->
                       <!--style="float: left;"   :style="{width:KD}"/>-->
            <!--<van-button-->
                    <!--type="primary" style="float:right;margin-top: 5px;height: 28px">发送</van-button>-->
        <!--</div>-->
    </div>
</template>
<script>
    import PageTitle from '@/components/PageTitle.vue';
    import { GetXTXX,InforMationRead } from '@/api/check.js';
    import {getDefaultAccount} from'@/api/user.js';

//    import { ImagePreview } from 'vant';

    export default {
        name: 'MyWebSocket',
        components: {
            PageTitle,
        },
        data () {
            return {
                loading: false,
                finished: false,
                container: null,
                isEnterSend: true,
                socket: null,
                receiveMsgText: [],
                sendMsg: {
                    Id: '',
                    senderId: '',
                    senderName: '',
                    targetId: '',
                    msgType: 1,
                    msg: '',
                    key:'0',
                },
                KD:'',
                height:{
                    height:'',
                },
                width:{
                    width:"",
                },
                timeoutObj: undefined,
                serverTimeoutObj: undefined,
                i: 0,
                lock:true,
                locks:true,
                listData: [],//图片数组
                index2:0,
                cardIDInfor:"",
                doctor:'',
                ckhm:this.$route.params.patientID,
                index:1,
                pd:true,
                hospitalName: '',
                imgSrc: '',
            }
        },
        created: function () {
          switch (process.env.NODE_ENV) {
            case 'PingYang':
              this.hospitalName = '平阳人民互联网医院';
              this.imgSrc = require('@/assets/PingYang.jpg');
              break;
            case 'CangNanKN':
              this.hospitalName = '苍南康宁互联网医院';
              this.imgSrc = require('../assets/系统头像@3x.png');
              break;
            case 'CangNanRM':
              this.hospitalName = '苍南人民互联网医院';
              this.imgSrc = require('@/assets/CangNanRM.jpg');
              break;
            case 'LongGang':
              this.hospitalName = '龙港人民互联网医院';
              this.imgSrc = require('../assets/LongGang.png');
              break;
            case 'LongWanRM':
              this.hospitalName = '温州市龙湾区第一人民医院';
              this.imgSrc = require('../assets/LongWanRM.png');
              break;
            case 'LaoNianBing':
              this.hospitalName = '温州市老年病医院';
              this.imgSrc = require('../assets/LaoNianBing.png');
              break;

          }
            // init sendMsg object
            this.GetID();

            this.hh();

        },
        mounted:function () {
            this.handleScroll();
        },
        methods: {
            handleScroll () {
                let that=this;
                document.getElementById("dhk").addEventListener("scroll", function () {
                    //变量scrollTop是滚动条滚动时，距离顶部的距离
                    var scrollTop =   document.getElementById("dhk").scrollTop ;
                    //变量windowHeight是可视区的高度
                    var windowHeight =   document.getElementById("dhk").clientHeight;
                    //变量scrollHeight是滚动条的总高度
                    var scrollHeight = document.getElementById("dhk").scrollHeight ;
                    //滚动条到底部的条件
                    if (scrollTop + windowHeight>= scrollHeight) {
                        //到了这个就可以进行业务逻辑加载后台数据了
                        that.GD();
                    }
                });
            },
            GD(){
                this.index++;
                this.pd=false
                getDefaultAccount(this.$store.getters.userInfo.UserId).then(
                    (result)=>{
                        let that=this;
                        that.cardIDInfor=result;
                        GetXTXX('patient-'+that.ckhm,20,that.index).then(
                            (result)=>{
                                for(let i=0;i<result.detail.length;i++) {
//                                    this.receiveMsgText = result.detail;
                                    this.receiveMsgText.unshift(result.detail[i]);
                                }

                                let TJ=[];
                                for(let i=0;i< result.detail.length;i++) {
                                    TJ.push(result.detail[i].id)
                                }
                                InforMationRead(TJ);

                            },
                            ()=>{}
                        );
                    },
                    ()=>{}
                );

            },
            GetID(){
                getDefaultAccount(this.$store.getters.userInfo.UserId).then(
                    (result)=>{
                        let that=this;
                        that.cardIDInfor=result;
                        GetXTXX('patient-'+that.ckhm,20,1).then(
                            (result)=>{
                                for(let i=0;i<result.detail.length;i++) {
//                                    this.receiveMsgText = result.detail;
                                    this.receiveMsgText.unshift(result.detail[i]);
                                }

                                let TJ=[];
                                for(let i=0;i< result.detail.length;i++) {
                                    TJ.push(result.detail[i].id)
                                }
                                InforMationRead(TJ);
                                this.timeOut = setTimeout(()=>{
                                    var div =document.getElementById('dhk');
                                    div.scrollTop = div.scrollHeight-div.clientHeight-10;

                                },100)
                            },
                            ()=>{}
                        );
                    },
                    ()=>{}
                );

            },
            hh(){
                this.height.height=document.documentElement.clientHeight-50+'px';
                this.width.width=document.documentElement.clientWidth-120+'px';
                this.KD=document.documentElement.clientWidth-80+'px';
            },
            PD(index){
                //不为第一条，且为今天,时间超过10分钟
//                if(index!=0 && this.receiveMsgText[index].sendDate.substring(0,10)==_this.gettime &&(this.receiveMsgText[index].sendDate.substring(11,13)*60)*1+(this.receiveMsgText[index].sendDate.substring(14,16))*1-(this.receiveMsgText[index-1].sendDate.substring(11,13)*60)*1-(this.receiveMsgText[index-1].sendDate.substring(14,16))*1>=10){
//                    return this.receiveMsgText[index].sendDate.substring(11,16);
//                }
                var _this = this;
                let yy = new Date().getFullYear();
                var mm =
                    new Date().getMonth() < 10
                        ? "0" + (new Date().getMonth() + 1)
                        : new Date().getMonth() + 1;
                var dd =
                    new Date().getDate() < 10
                        ? "0" + new Date().getDate()
                        : new Date().getDate();
                _this.gettime = yy + "-" + mm + "-" + dd;

//                    let dat = this.receiveMsgText[index].sendDate.substring(0, 10) + " " + this.receiveMsgText[index].sendDate.substring(11, 19);

                    if (this.receiveMsgText[index].sendDate.substring(0, 10) == _this.gettime) {
                        return this.receiveMsgText[index].sendDate.substring(11, 16);
                    }
                    else{
                        return this.receiveMsgText[index].sendDate.substring(0, 10) + " " + this.receiveMsgText[index].sendDate.substring(11, 16);
                    }
//
//                }
//                else{
//                    if (this.receiveMsgText[index].sendDate.substring(0, 10) == _this.gettime) {
//                        return this.receiveMsgText[index].sendDate.substring(11, 16);
//                    }
//                    else{
//                        return this.receiveMsgText[index].sendDate.substring(0, 10) + " " + this.receiveMsgText[index].sendDate.substring(11, 16);
//                    }
//
//                }
            },
            getTimedata(myDate,dat){

                //  split("/")

                let getYeardata = dat.split(" ")[0];
                let getTimedata = dat.split(" ")[1];
                //  年月日  时分秒
                let beforeYear = getYeardata.split("-")[0];
                let beforeMonth = getYeardata.split("-")[1];
                let beforeDate = getYeardata.split("-")[2];
                let beforeHours = getTimedata.split(":")[0];
                let beforeMinutes = getTimedata.split(":")[1];
                let beforeSeconds = getTimedata.split(":")[2];

                //  var myDate = new Date();
//myDate.getYear(); //获取当前年份(2位)
//myDate.getFullYear(); //获取完整的年份(4位,1970-????)
//myDate.getMonth(); //获取当前月份(0-11,0代表1月)         // 所以获取当前月份是
//myDate.getMonth()+1;
//myDate.getDate(); //获取当前日(1-31)
//myDate.getDay(); //获取当前星期X(0-6,0代表星期天)
//myDate.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
//myDate.getHours(); //获取当前小时数(0-23)
//myDate.getMinutes(); //获取当前分钟数(0-59)
//myDate.getSeconds(); //获取当前秒数(0-59)
//myDate.getMilliseconds(); //获取当前毫秒数(0-999)
//myDate.toLocaleDateString(); //获取当前日期
//var mytime=myDate.toLocaleTimeString(); //获取当前时间
//myDate.toLocaleString( ); //获取日期与时间

                let getYeardatas = myDate.split(" ")[0];
                let getTimedatas = myDate.split(" ")[1];
                let afterYear = getYeardatas.split("-")[0];
                let afterMonth = getYeardatas.split("-")[1];
                let afterDate = getYeardatas.split("-")[2];
                let afterHours = getTimedatas.split(":")[0];
                let afterMinutes = getTimedatas.split(":")[1];
                let afterSeconds = getTimedatas.split(":")[2];

// 计算差值
                let getDifference = (afterYear - beforeYear) * 365 * 24 * 60 * 60;
                getDifference  += (afterMonth - beforeMonth) * 30 * 24 * 60 * 60;
                getDifference  += (afterDate- beforeDate ) * 24 * 60 * 60;
                getDifference  += (afterHours - beforeHours ) * 60 * 60;
                getDifference   += (afterMinutes- beforeMinutes  ) * 60;
                getDifference    +=(afterSeconds - beforeSeconds );

                return getDifference;

            }
        }
    }
</script>
<style lang="less">
    .communicationInput input{
        border: 2px solid #1678FD;
        padding: 0 5px;
    }
</style>
<style scoped lang="less">
    @import "../style/var.less";
    .van-cell::after {
        border-bottom: 0;
    }
    .doctorCommunicationInputs{
        background-color: @white;
        color: @green;
        padding: 5px 10px;
        border-radius: 8px;
        box-shadow: 0px 0px 5px 0px @gray-5;
        margin:5px;
        max-width: 65vw;
    }
    .customerCommunicationInputs{
        background-color: @green;
        color: @white;
        padding: 5px 10px;
        border-radius: 8px;
        margin: 0 5px;
        max-width: 65vw;
    }

</style>